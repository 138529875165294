.contactCont {
  position: fixed;
  padding: 0.5rem 0;
  bottom: 1.75rem;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    margin: 0 !important;
  }
  p {
    color: #ffffff;
    padding-left: 0.625rem;
  }
}
.privacy {
  position: fixed;
  padding: 0.5rem 0;
  bottom: 0;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    margin: 0 !important;
  }
  p {
    color: #ffffff;
    padding-left: 0.625rem;
  }
}
.footer_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
}
.root_loginTerms {
  padding: 2rem 1rem;
}
.hdr_loginTerms {
  font-weight: bold;
  color: #ffffff;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.body_loginTerms {
  color: #eeeeee;
  text-align: left;
  font-size: 0.8125rem;
  line-height: 1rem;
}
