.signUpCpmponent {
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  padding-top: 0.88rem;
  img {
    margin: 0 auto 1rem;
  }

  p {
    font-weight: bold;
    color: #fff;
    font-size: 0.69rem;
    color: #ffffff;
    letter-spacing: 0;
    padding-bottom: 0.75rem;
  }
  h4 {
    color: #808080;
    font-size: 1rem;
    padding-top: 0.88rem;
  }
}
.formArea {
  background: #ffffff;
  box-shadow: 0 6px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 26.5rem;
  position: relative;
  margin-bottom: 0.9rem;
  ul {
    display: flex;
    padding: 0 1rem;
    justify-content: space-evenly;
    align-items: center;
    li {
      padding: 0.68rem 0;
      width: 50%;
      font-size: 0.88rem;
      color: #999999;
      cursor: pointer;
      &.active {
        color: #3c99eb;
        border-bottom: 3px solid #3c99eb;
      }
    }
  }
}
.winIcon {
  width: 5.44rem;
  margin: 0 auto;
}
.verfyIcon {
  width: 6.88rem;
}
.verifyArea {
  p {
    font-weight: 600;
    font-size: 0.75rem;
    color: #999999;
    width: 80%;
    margin: 0 auto;
    padding-top: 0.3rem;
    line-height: 0.94rem;
    padding-bottom: 1.06rem;
  }

  input {
    width: 85%;
    & :focus {
      outline: none;
    }
    & :hover {
      outline: none;
    }
    & :active {
      outline: none;
    }
  }
}

.submitBtn {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  left: 0;
}
.registerForm {
  width: 90%;
  margin: 0 auto;

  input {
    width: 100%;
  }

  > div {
    padding-top: 1.5rem;
    position: relative;
  }

  input {
    + p {
      display: block;
      position: absolute;
      top: 100%;
      color: red;
      left: 0;
      width: 100%;
      text-align: left;
      font-size: 0.7rem;
    }
  }
}
.ageandSex {
  display: flex;
  justify-content: space-between;
  select {
    width: 48%;
    display: inline-block;
    background: transparent;
    option {
      background-color: white;
    }
  }
}

.addPAdding {
  padding-top: 1.15rem;
}
.verifyHeader {
  position: relative;
  p {
    color: #ffffff;
    padding-top: 5px;
    text-align: center;
  }

  .user {
    button {
      background: rgba(0, 0, 0, 0.27);
      border: none;
      border-radius: 16px;
      border-radius: 16px;
      width: 3.5rem;
      position: absolute;
      right: 0;
      min-width: 1px;
      top: 0;
      text-shadow: none;
      box-shadow: none;
    }
  }
}
.nameCont {
  position: relative;
  padding-top: 0 !important;
  label {
    position: absolute;
  }
}
.verifYError {
  width: 85% !important;
  position: absolute;
  top: 100% !important;
  color: red !important;
  left: 50% !important;
  transform: translate(-50%, 0%);
  text-align: left;
  font-size: 0.7rem !important;
}
.loginNumber {
  position: relative;
  .error {
    position: absolute;
    top: 100% !important;
    color: red !important;
    left: 50% !important;
    transform: translate(-50%, 0%);
    text-align: left;
    font-size: 0.7rem !important;
  }
}
.otpVerify {
  position: relative;
}
.para_terms {
  font-size: 0.91rem;
  margin: 1.25rem;
  color: #808080;
}
.link_terms {
  color: #3c99eb;
}
.ageError,
.ageSex {
  position: absolute;
  color: red;
  font-size: 0.7rem !important;
  top: 110%;
}
.ageSex {
  right: 0;
}
.genderErrorBox {
  border-color: red;
}
