.endQuiz {
  text-align: center;
  padding-top: 0.88rem;
  width: 90%;
  height: 100vh;
  margin: 0 auto;

  .timerArea {
    background: rgba(255, 255, 255, 0.98);
    padding: 0.81rem 1rem;
    text-align: center;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 0.88rem;

    p {
      font-weight: bold;
      line-height: 1.1rem;
      font-size: 0.89rem;
      color: #3c99eb;
      letter-spacing: 0;
      text-align: center;
    }
    .timerIcon {
      padding-bottom: 0.74rem;
      img {
        width: 4.64rem;
        margin: 0 auto;
      }
    }
  }
}
.endQuizBanner {
  padding: 0.8rem 0;
}
.downLoadAreaEnd {
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0.75rem 0 0rem 0;
  & img {
    border-radius: 0.375rem;
  }
}
