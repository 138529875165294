.root {
  padding: 0.875rem;
  height: 100vh;
}
.hdr_winEasy {
  text-align: center;
  margin-bottom: 1px;
  // font-family: CooperHewitt-HeavyItalic;
  font-size: 1.25rem;
  color: #f4ef1e;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.subhdr_presentedBy {
  // font-family: Rajdhani-Bold;
  font-size: 0.6875rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.875rem;
}
.hdr_image {
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  & img {
    border-radius: 0.5rem;
  }
}
.footer_playOnlyOnce {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.75rem;
  color: #cccccc;
  letter-spacing: 0;
  text-align: center;
  margin: 0 1.75rem;
}
.root_download {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  margin-bottom: 0.75rem;
  & img {
    border-radius: 0.375rem;
  }
}
.txt_download {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  color: #ff8500;
  letter-spacing: 0;
  line-height: 1rem;
}
.btn_download {
  background-image: linear-gradient(180deg, #fbbf00 0%, #ff8519 100%);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  font-family: "Rajdhani", sans-serif;
  font-size: 0.8125rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.94rem;
}
.footer_img {
  margin-bottom: 0.75rem;
  & img {
    border-radius: 0.5rem;
  }
}
.root_body {
  background: #ffffff;
  border-radius: 0.5rem;
  margin-bottom: 0.63rem;
  padding: 0.61rem 0;
}
.img_won {
  height: 4rem;
  margin: 0rem auto 0.41rem auto;
}
.txt_winner {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.875rem;
  color: #1cc774;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.69rem;
}
.root_coupon {
  padding: 0 0.63rem 0.69rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.txt_coupon,
.txt_validity {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.915rem;
  color: #555;
  font-weight: bold;
  letter-spacing: 0;
  padding-bottom: 0.19rem;
}
.root_terms {
  //background: linear-gradient(0deg, #3593e6 0%, #17cae6 100%);
  background: linear-gradient(-180deg, #48cfad 0%, #19a784 100%);
  border: 1px solid #ade1ff;
  border-radius: 1.25rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 0.88rem;
  min-height: 2rem;
  //color: #10518a;
  color: #ffffff;
  letter-spacing: 0;
  align-self: center;
  padding: 0rem 0.88rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    width: 30%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    position: absolute;
    top: 0;
    -webkit-animation: shimmer 2s infinite 3s; /* Safari 4.0 - 8.0 */
    animation: shimmer 2s infinite 3s;
    opacity: 0;
  }
}
.hdr_wrongAnswer {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #f4818f;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.19rem;
}
.subhdr_wrongAnswer {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.81rem;
  color: #666666;
  letter-spacing: 0;
  text-align: center;
  padding-bottom: 0.88rem;
}
.row_didnotwin {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 1.44rem 0 0.88rem;
}
.img_didnotwin {
  height: 4.25rem;
  width: 4.25rem;
  margin-right: 0.88rem;
  margin-bottom: 1.25rem;
}
.txt_didnotwin {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #666666;
  letter-spacing: 0;
  padding-bottom: 0.25rem;
}
.subtxt_didnotwin,
.subtxt_correct {
  font-family: "Rajdhani", sans-serif;
  font-size: 0.81rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 1.125rem;
}
.subtxt_correct {
  line-height: 1rem;
}
.root_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.img_wrong {
  height: 0.74rem;
  margin-right: 0.25rem;
}
.txt_correct {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #1cc774;
  letter-spacing: 0;
  text-align: center;
}
.subtxt_correct {
  margin: 0 1.5rem;
  text-align: center;
  padding: 0.2rem 0 0.5rem 0;
}
.root_termsScreen {
  background: #3c99eb;
  height: 100vh;
  padding: 1rem;
}
.body_termsScreem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.lbl_couponTerms {
  font-size: 1rem;
  color: #ffffff;
}
.btn_closeTerms {
  display: none;
  background: #f7f7f7;
  border-radius: 1rem;
  font-size: 0.8125rem;
  color: #999999;
  text-align: center;
  padding: 0.31rem 0.44rem;
}
.date_answers {
  text-align: center;
  padding-bottom: 0.51rem;
  color: #777;
  font-weight: 650;
  font-size: 0.875rem;
}
.root_sharePanel {
  padding-top: 0.2rem;
  padding-bottom: 0.69rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & button {
    min-width: 0;
    box-shadow: none;
    background-image: none;
    margin-right: 0.5rem;
  }
}
@keyframes shimmer {
  0% {
    left: 0%;
    opacity: 1;
  }
  10% {
    left: 90%;
    opacity: 0;
  }
  45% {
    top: 0%;
    opacity: 0;
  }
  75% {
    top: 0%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}
.btn_moreQuizzes {
  background: linear-gradient(0deg, #3593e6 0%, #17cae6 100%);
  border: 1px solid #ade1ff;
  border-radius: 1.25rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 0.88rem;
  color: #ffffff;
  letter-spacing: 0;
  padding: 0.5rem 0.88rem;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
.txt_shareWithFrnds {
  color: #fff;
  text-align: center;
  padding-bottom: 0.251rem;
}
